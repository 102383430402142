<template>
  <div class="thumbnail">
    <div class="thumbnail-image">
      <picture>
        <source :srcset="image" type="image/webp">
        <img :src="image" loading="lazy">
      </picture>
    </div>
  </div>
</template>

<style lang="scss">
@import './Thumbnails';
</style>

<script>
export default {
  props: {
    image: String,
  },
};
</script>
